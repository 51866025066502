import { CommunityCreationFormState } from "@/authentication/create-community/CreateCommunityPage"
import { CreateCommunityPageMutation } from "@/authentication/create-community/__generated__/CreateCommunityPageMutation.graphql"
import PublicAiApi from "@/common/PublicAiApi"
import FormStore from "@/core/form/store/FormStore"
import RestfulUtil from "@/core/restful/RestfulUtil"
import OrganizationDescriptionInputWithCounter from "@/organization/edit/form/OrganizationDescriptionInputWithCounter"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { displayErrorToast } from "@components/toast/ToastProvider"
import { DiscoButton, DiscoFormControl, DiscoLinkInput, DiscoText } from "@disco-ui"
import { observer } from "mobx-react-lite"
import { useState } from "react"

interface Props {
  validationForm: FormStore<CommunityCreationFormState, CreateCommunityPageMutation>
}

function AddCommunityDescriptionForm({ validationForm }: Props) {
  const [websiteUrl, setWebsiteUrl] = useState<string>("")
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const classes = useStyles()

  return (
    <div data-testid={"AddCommunityDescriptionForm.wrapper"}>
      <DiscoText variant={"heading-lg"} align={"left"}>
        {"Write a Description"}
      </DiscoText>
      <DiscoText variant={"body-md"} marginBottom={1} marginTop={1} align={"left"}>
        {
          "Share the unique story and essence of your community through a thoughtful description."
        }
      </DiscoText>
      <DiscoText
        align={"left"}
        variant={"body-xs"}
        color={"groovy.grey.400"}
        marginBottom={4}
      >
        {"(Don’t worry, you can change this later!)"}
      </DiscoText>

      <DiscoFormControl
        label={"Generate a description based on a website"}
        testid={"AddCommunityDescriptionForm.websiteUrl"}
        optional
        errorMessages={validationForm.errorsByField.websiteUrl}
      >
        <div className={classes.websiteUrlContainer}>
          <DiscoLinkInput
            data-testid={"AddCommunityDescriptionForm.websiteUrl.input"}
            value={websiteUrl}
            onChange={(val) => {
              validationForm.removeErrors()
              setWebsiteUrl(val)
            }}
            fullWidth
          />

          <DiscoButton
            className={classes.writeWithAiButton}
            leftIcon={"stars"}
            onClick={handleGenerateDescription}
            shouldDisplaySpinner={isLoading}
            disabled={isLoading}
          >
            {"Write it for me!"}
          </DiscoButton>
        </div>
      </DiscoFormControl>

      <OrganizationDescriptionInputWithCounter
        description={validationForm.state.organizationDescription?.description}
        onChange={(val) =>
          (validationForm.state.organizationDescription!.description = val)
        }
        testid={"AddCommunityDescriptionForm"}
      />
    </div>
  )

  async function handleGenerateDescription() {
    setIsLoading(true)
    const response = await PublicAiApi.generateOrganizationDescription({
      websiteUrl,
    })

    const error = await RestfulUtil.getValidationError(response)
    if (error) {
      validationForm.addError(error)
      setIsLoading(false)
      return
    }

    if (!response.ok || !response.body) {
      setIsLoading(false)
      return
    }

    let generatedDescription = ""
    await RestfulUtil.handleStream(response.body, (decodedChunk) => {
      generatedDescription += decodedChunk
      validationForm.state.organizationDescription!.description = generatedDescription
      return true
    })

    if (!generatedDescription.trim()) {
      displayErrorToast("Unable to generate anything based on the prompt")
    }

    setIsLoading(false)
  }
}

const useStyles = makeUseStyles((theme) => ({
  websiteUrlContainer: {
    display: "flex",
    gap: theme.spacing(2),
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },
  writeWithAiButton: {
    background: theme.palette.aiGradient.aiDark,
  },
}))

export default observer(AddCommunityDescriptionForm)
