import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoFormControl, DiscoIconKinds, DiscoText } from "@disco-ui"
import DiscoRadioBox from "@disco-ui/radio/DiscoRadioBox"
import { RadioGroup } from "@material-ui/core"
import { TestIDProps } from "@utils/typeUtils"
import { ReactNode } from "react"

type Props<T extends string> = TestIDProps & {
  title: string
  subtitle: string
  value: T | null | undefined
  onChange: (value: T) => void
  options: {
    value: T
    title?: ReactNode
    subtitle?: ReactNode
    icon?: DiscoIconKinds
  }[]
}

export default function CommunityMultipleChoiceQuestion<T extends string>({
  testid = "CommunityMultipleChoiceQuestion",
  title,
  subtitle,
  value,
  onChange,
  options,
}: Props<T>) {
  const classes = useStyles()

  return (
    <div data-testid={`${testid}.wrapper`}>
      <DiscoText variant={"heading-lg"} testid={`${testid}.title`}>
        {title}
      </DiscoText>
      <DiscoText variant={"body-md"} marginTop={1} marginBottom={3}>
        {subtitle}
      </DiscoText>
      <DiscoFormControl testid={`${testid}.options`}>
        <RadioGroup
          value={value || null}
          onChange={(e) => onChange(e.target.value as T)}
          className={classes.options}
        >
          {options.map((option) => (
            <DiscoRadioBox
              key={option.value}
              testid={`${testid}.option.${option.value}`}
              value={option.value}
              title={option.title}
              subtitle={option.subtitle}
              icon={option.icon}
              iconActive
              showIconBackground
              variant={"compact"}
              classes={{ radioRoot: classes.option }}
              showRadio
            />
          ))}
        </RadioGroup>
      </DiscoFormControl>
    </div>
  )
}

const useStyles = makeUseStyles((theme) => ({
  options: {
    display: "grid",
    gap: theme.spacing(2),
    gridTemplateColumns: "1fr 1fr",
    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "1fr",
    },
  },
  option: {
    width: "100%",
  },
}))
