import { CommunityCreationFormState } from "@/authentication/create-community/CreateCommunityPage"
import { CreateCommunityPageMutation } from "@/authentication/create-community/__generated__/CreateCommunityPageMutation.graphql"
import FormStore from "@/core/form/store/FormStore"
import CommunityMultipleChoiceQuestion from "@/organization/create/form/CommunityMultipleChoiceQuestion"
import { observer } from "mobx-react-lite"

type Props = {
  validationForm: FormStore<CommunityCreationFormState, CreateCommunityPageMutation>
}

function CommunityTemplateQuestion({ validationForm }: Props) {
  return (
    <CommunityMultipleChoiceQuestion
      testid={"CommunityTemplateQuestion"}
      title={"Select a community template"}
      subtitle={`Select the template that best fits your community to set up default sections and spaces.`}
      options={[
        {
          title: "Virtual School or Academy",
          subtitle: "For virtual schools running courses.",
          icon: "graduation-cap",
          value: "virtual_school",
        },
        {
          title: "Membership",
          subtitle: "Organized spaces for different groups.",
          icon: "shield",
          value: "membership",
        },
        {
          title: "Association",
          subtitle: "For member based communities.",
          icon: "shield",
          value: "association",
        },
        {
          title: "Accelerator or Incubator",
          subtitle: "For startup incubators and accelerators.",
          icon: "lightbulb",
          value: "incubator",
        },
        {
          title: "Product Enablement",
          subtitle: "Space for your product's customers. ",
          icon: "bag",
          value: "product_enablement",
        },
        {
          title: "Training or Certification",
          subtitle: "For a certification or training program.",
          icon: "achievement",
          value: "training_program",
        },
        {
          title: "Internal Team",
          subtitle: "Home for your organization's knowledge.",
          icon: "group-share",
          value: "internal_team",
        },
        {
          title: "Blank",
          subtitle: "Start building from a clean slate.",
          icon: "star",
          value: "basic_community",
        },
      ]}
      value={validationForm.state.template}
      onChange={(template) => {
        validationForm.state.template = template
      }}
    />
  )
}

export default observer(CommunityTemplateQuestion)
