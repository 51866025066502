import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { useLabels } from "@/core/context/LabelsContext"
import { OrganizationMemberMultiSelect_PaginationQuery } from "@/organization/member/common/__generated__/OrganizationMemberMultiSelect_PaginationQuery.graphql"
import { GlobalID } from "@/relay/RelayTypes"
import Relay from "@/relay/relayUtils"
import { ProfileAvatarWithDetailsFragment$key } from "@/user/common/profile-avatar-with-details/__generated__/ProfileAvatarWithDetailsFragment.graphql"
import UserDropdownItem from "@/user/common/UserDropdownItem"
import { DiscoInputSkeleton } from "@disco-ui"
import DiscoMultiSelect, {
  DiscoMultiSelectOption,
  DiscoMultiSelectProps,
} from "@disco-ui/select/DiscoMultiSelect"
import DiscoTag from "@disco-ui/tag/DiscoTag"
import { AutocompleteGetTagProps } from "@material-ui/lab"
import { ArrayUtils } from "@utils/array/arrayUtils"
import { TestIDProps } from "@utils/typeUtils"
import { graphql } from "react-relay"

interface Props
  extends TestIDProps,
    Pick<DiscoMultiSelectProps, "autoFocusInput" | "limit" | "disableClearable"> {
  placeholder?: string
  values: GlobalID[]
  onChange: (members: { id: GlobalID; memberId: GlobalID }[]) => void
  hideUserIds?: GlobalID[]
  productIds?: GlobalID[] | null
  inProductsViewerIsAdminOf?: boolean
  renderTags?: (
    membership: { member: ProfileAvatarWithDetailsFragment$key },
    getTagProps: AutocompleteGetTagProps
  ) => JSX.Element
}

function OrganizationMemberMultiSelect(props: Props) {
  const {
    testid = "OrganizationMemberMultiSelect",
    placeholder,
    values,
    onChange,
    productIds,
    autoFocusInput,
    inProductsViewerIsAdminOf,
    renderTags: customRenderTags,
    limit,
    disableClearable,
  } = props

  const activeOrganization = useActiveOrganization()!

  const { data, pagination } =
    Relay.useRefetchablePaginationQuery<OrganizationMemberMultiSelect_PaginationQuery>(
      graphql`
        query OrganizationMemberMultiSelect_PaginationQuery(
          $id: ID!
          $first: Int!
          $after: String
          $productIds: [ID!]
          $search: String
          $selectedIds: [ID!]
          $inProductsViewerIsAdminOf: Boolean
        ) {
          node(id: $id) {
            ... on Organization {
              organizationMemberships(
                after: $after
                first: $first
                productIds: $productIds
                search: $search
                excludeOrganizationMembershipIds: $selectedIds
                inProductsViewerIsAdminOf: $inProductsViewerIsAdminOf
              )
                @connection(
                  key: "OrganizationMemberMultiSelect_organizationMemberships"
                ) {
                edges {
                  node {
                    id
                    email
                    ...UserDropdownItemPrivateFragment
                    member {
                      id
                      fullName
                      ...UserDropdownItemFragment
                      ...ProfileAvatarWithDetailsFragment
                    }
                  }
                }
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                  startCursor
                  endCursor
                }
              }
              selected: organizationMemberships(organizationMembershipIds: $selectedIds) {
                edges {
                  node {
                    id
                    email
                    ...UserDropdownItemPrivateFragment
                    member {
                      id
                      fullName
                      ...UserDropdownItemFragment
                      ...ProfileAvatarWithDetailsFragment
                    }
                  }
                }
              }
            }
          }
        }
      `,
      {
        id: activeOrganization.id,
        selectedIds: values,
        productIds,
        inProductsViewerIsAdminOf,
      },
      { connectionName: "node.organizationMemberships" }
    )

  // Combine the selected memberships with the paginated memberships
  // This is needed so the selected memberships are always visible and they may not necessarily be in the paginated list
  const memberships = Relay.connectionToArray(data?.node?.organizationMemberships).concat(
    values.length ? Relay.connectionToArray(data?.node?.selected) : []
  )

  const membershipsById = ArrayUtils.mapBy(memberships, "id")

  const hideUserIds = new Set(props.hideUserIds)
  const visibleMemberships =
    hideUserIds.size > 0
      ? memberships.filter((m) => !hideUserIds.has(m.member.id))
      : memberships

  const labels = useLabels()

  return (
    <DiscoMultiSelect
      testid={testid}
      placeholder={placeholder || labels.product_member.plural}
      values={values}
      onChange={handleChange}
      renderOption={renderOption}
      renderTags={renderTags}
      options={visibleMemberships.map((om) => ({
        key: om.id,
        value: om.id,
        title: om.member.fullName,
      }))}
      additionalSubmitKeys={[","]}
      autoFocusInput={autoFocusInput}
      pagination={pagination}
      limit={limit}
      disableClearable={disableClearable}
      filterOptions={{ maxVisible: 100 }}
    />
  )

  // Add memberId to the change for ease of finding userId for each membership..
  function handleChange(organizationMembershipIds: GlobalID[]) {
    onChange(
      organizationMembershipIds
        .filter((id) => membershipsById[id])
        .map((id) => ({
          id: membershipsById[id].id,
          memberId: membershipsById[id].member.id,
        }))
    )
  }

  function renderTags(
    value: DiscoMultiSelectOption[],
    getTagProps: AutocompleteGetTagProps
  ) {
    if (customRenderTags) {
      if (!value.length) return null
      const membership = membershipsById[value[0].value]
      return customRenderTags(membership, getTagProps)
    }
    return value.map((pm, index) => (
      <DiscoTag
        key={pm.value}
        testid={`${testid}.option.remove.${pm.title}`}
        {...getTagProps({ index })}
        name={pm.title}
      />
    ))
  }

  function renderOption(option: DiscoMultiSelectOption) {
    const membership = membershipsById[option.value]
    if (!membership) return <></>
    return (
      <UserDropdownItem
        userKey={membership.member}
        privateUserKey={membership}
        testid={`${testid}.option.${option.title}`}
      />
    )
  }
}

export default Relay.withSkeleton<Props>({
  component: OrganizationMemberMultiSelect,
  skeleton: () => <DiscoInputSkeleton />,
})
