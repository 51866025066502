import ThreeDotLoaderGradient from "@assets/images/onboarding/processing/three-dot-loader-gradient.svg"
import TranslucentCard1 from "@assets/images/onboarding/processing/translucent-card-1.svg"
import TranslucentCard2 from "@assets/images/onboarding/processing/translucent-card-2.svg"
import TranslucentCard3 from "@assets/images/onboarding/processing/translucent-card-3.svg"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoText } from "@disco-ui"

export default function CreateCommunityLoadingPage() {
  const classes = useStyles()

  return (
    <div className={classes.page}>
      <div className={classes.content} data-testid={"CreateCommunityLoadingPage.wrapper"}>
        <ThreeDotLoaderGradient />
        <DiscoText variant={"heading-md"} color={"common.white"} marginTop={2}>
          {"Time to Get Groovy!"}
        </DiscoText>
        <DiscoText variant={"body-md"} color={"common.white"} marginTop={2}>
          {
            "You've completed setup. We're busy on the dance floor building your learning space and generating content."
          }
        </DiscoText>
        <div className={classes.cards}>
          <TranslucentCard2 className={classes.card} style={{ top: 0, left: 82 }} />
          <TranslucentCard1 className={classes.card} style={{ top: 55, left: 392 }} />
          <TranslucentCard3 className={classes.card} style={{ top: 0, right: 14 }} />
          <TranslucentCard3 className={classes.card} style={{ top: 346, left: 0 }} />
          <TranslucentCard2 className={classes.card} style={{ top: 305, right: 0 }} />
        </div>
      </div>
    </div>
  )
}

const useStyles = makeUseStyles((theme) => ({
  page: {
    height: "100%",
    backgroundImage: theme.palette.gradient.newCommunityOnboarding,
    overflow: "hidden",
    position: "relative",
  },
  content: {
    position: "absolute",
    top: "30%",
    left: "50%",
    transform: "translateX(-50%)",
    width: "100%",
    maxWidth: "500px",
    textAlign: "center",
    padding: theme.spacing(3),
  },
  cards: {
    position: "absolute",
    width: "1267px",
    top: `calc(100% + ${theme.spacing(6)}px)`,
    left: "50%",
    transform: "translateX(-50%)",
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  card: {
    position: "absolute",
  },
}))
