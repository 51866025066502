import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import {
  OrganizationMemberEmailMultiSelectQuery,
  OrganizationRole,
  ProductRole,
} from "@/organization/member/common/__generated__/OrganizationMemberEmailMultiSelectQuery.graphql"
import { GlobalID } from "@/relay/RelayTypes"
import Relay from "@/relay/relayUtils"
import UserDropdownItem from "@/user/common/UserDropdownItem"
import AvatarPlaceholder from "@components/avatar/placeholder/AvatarPlaceholder"
import { DiscoInputSkeleton } from "@disco-ui"
import DiscoDropdownItem from "@disco-ui/dropdown/DiscoDropdownItem"
import DiscoEmailMultiSelect from "@disco-ui/select/DiscoEmailMultiSelect"
import { DiscoMultiSelectOption } from "@disco-ui/select/DiscoMultiSelect"
import { TestIDProps } from "@utils/typeUtils"
import { useMemo } from "react"
import { graphql, useLazyLoadQuery } from "react-relay"

export interface OrganizationMemberEmailMultiSelectProps extends TestIDProps {
  placeholder?: string
  emails: string[]
  userIds: GlobalID[]
  hasErrors?: boolean
  onChange: (emails: string[], userIds: GlobalID[]) => void
  /** organization membership role */
  roles?: OrganizationRole[]
  /** exclude product memberships' emails in the options */
  excludeProductMemberships?: {
    productId: GlobalID
    roles?: ProductRole[]
  }
  emailsOnly?: boolean
  disabled?: boolean
}

function OrganizationMemberEmailMultiSelect(
  props: OrganizationMemberEmailMultiSelectProps
) {
  const {
    testid = "OrganizationMemberEmailMultiSelect",
    placeholder,
    excludeProductMemberships,
    emails,
    hasErrors,
    userIds,
    roles,
    onChange,
    emailsOnly = false,
    disabled,
  } = props

  const activeOrganization = useActiveOrganization()!
  const canReadPrivate = activeOrganization.viewerPermissions.has("members.read_private")

  const { organization } = useLazyLoadQuery<OrganizationMemberEmailMultiSelectQuery>(
    graphql`
      query OrganizationMemberEmailMultiSelectQuery(
        $id: ID!
        $roles: [OrganizationRole!]
        $canReadPrivate: Boolean!
        $excludeProductIds: [ID!]
        $excludeProductRoles: [ProductRole!]
      ) {
        organization: node(id: $id) {
          id
          ... on Organization {
            organizationMemberships(
              orderBy: recently_active
              roles: $roles
              excludeProductIds: $excludeProductIds
              excludeProductRoles: $excludeProductRoles
            ) {
              edges {
                node {
                  id
                  ...UserDropdownItemPrivateFragment @include(if: $canReadPrivate)
                  email @include(if: $canReadPrivate)
                  member {
                    id
                    fullName
                    ...UserDropdownItemFragment
                  }
                }
              }
            }
          }
        }
      }
    `,
    {
      id: activeOrganization.id,
      roles,
      canReadPrivate,
      excludeProductIds: excludeProductMemberships?.productId
        ? [excludeProductMemberships.productId]
        : null,
      excludeProductRoles: excludeProductMemberships?.roles || null,
    },
    {
      fetchPolicy: "store-and-network",
    }
  )

  // need to create options for any emails present on inital render or will be removed by DiscoMultiSelect
  // any new emails added will be handed internally in DiscoMultiSelect
  const emailOptions: DiscoMultiSelectOption[] = useMemo(
    () =>
      emails.map((em) => ({
        id: em,
        title: em,
        value: em,
        searchable: [em],
      })),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  if (!organization) return null
  const organizationMemberships = Relay.connectionToArray(
    organization?.organizationMemberships
  )

  const membershipsById = organizationMemberships.reduce((acc, om) => {
    acc[om.member.id] = om
    return acc
  }, {} as Record<string, (typeof organizationMemberships)[0]>)

  const options: DiscoMultiSelectOption[] = organizationMemberships.map((om) => ({
    id: om.id,
    title: om.member.fullName,
    value: om.member.id,
    searchable: [om.member.fullName, om.email],
  }))

  return (
    <DiscoEmailMultiSelect
      testid={testid}
      placeholder={
        placeholder || (emailsOnly ? "Enter an email" : "Enter a name or an email")
      }
      hasErrors={hasErrors}
      values={userIds.concat(emails)}
      onChange={handleOnChange}
      renderOption={renderOption}
      options={emailsOnly ? undefined : options.concat(emailOptions)}
      disabled={disabled}
    />
  )

  function renderOption(option: DiscoMultiSelectOption) {
    const membership = membershipsById[option.value]
    if (!membership)
      return (
        <DiscoDropdownItem
          testid={`OrganizationMemberEmailMultiSelect.option.${option.title}`}
          icon={
            <AvatarPlaceholder
              testid={`${testid}.avatar`}
              text={option.title[0]}
              size={40}
            />
          }
          className={"fs-mask"}
          title={option.title}
          subtitle={option.value}
        />
      )
    return (
      <UserDropdownItem
        userKey={membership.member}
        privateUserKey={canReadPrivate ? membership : null}
        testid={`OrganizationMemberEmailMultiSelect.option.${option.title}`}
      />
    )
  }

  function handleOnChange(selectedValues: string[]) {
    const selectedEmails = []
    const selectedUserIds = []
    for (const val of selectedValues) {
      if (organizationMemberships.some((om) => om.member.id === val)) {
        selectedUserIds.push(val)
      } else {
        selectedEmails.push(val)
      }
    }
    onChange(selectedEmails, selectedUserIds)
  }
}

export default Relay.withSkeleton<OrganizationMemberEmailMultiSelectProps>({
  component: OrganizationMemberEmailMultiSelect,
  skeleton: () => <DiscoInputSkeleton flexGrow={1} />,
})
