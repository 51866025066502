import { CommunityCreationStep } from "@/authentication/create-community/CreateCommunityPage"
import { useFormStore } from "@/core/form/store/FormStore"
import ROUTE_NAMES from "@/core/route/util/routeNames"
import {
  CompleteCommunityCreationInput,
  useCreateCommunityMutation,
} from "@/organization/create/hooks/__generated__/useCreateCommunityMutation.graphql"
import { useCallback } from "react"
import { useHistory } from "react-router-dom"
import { graphql } from "relay-runtime"

function useCreateCommunity() {
  const history = useHistory()

  const form = useFormStore<useCreateCommunityMutation>(
    graphql`
      mutation useCreateCommunityMutation($input: CompleteCommunityCreationInput!) {
        response: completeCommunityCreation(input: $input) {
          node {
            slug
            id
            primaryDomain
            products {
              edges {
                node {
                  id
                  slug
                  name
                }
              }
            }
            badge {
              id
              color
            }
          }
          errors {
            field
            message
          }
        }
      }
    `,
    {
      user: null,
      organization: null,
    },
    {
      requireChangeToSubmit: false,
    }
  )

  const handleCreateCommunity = useCallback(
    async (
      input: CompleteCommunityCreationInput
    ): Promise<{ slug: string; primaryDomain: string } | false> => {
      function redirectToFormStep(field: string) {
        switch (field) {
          case "organization": {
            const step: CommunityCreationStep = "organization"
            history.replace(
              ROUTE_NAMES.ONBOARDING.NEW_COMMUNITY,
              JSON.parse(JSON.stringify({ ...form.state, organization: undefined, step }))
            )
            break
          }
          case "organizationDescription": {
            const step: CommunityCreationStep = "organization_description"
            history.replace(
              ROUTE_NAMES.ONBOARDING.NEW_COMMUNITY,
              JSON.parse(JSON.stringify({ ...form.state, step }))
            )
            break
          }
          case "template": {
            const step: CommunityCreationStep = "template"
            history.replace(
              ROUTE_NAMES.ONBOARDING.NEW_COMMUNITY,
              JSON.parse(JSON.stringify({ ...form.state, template: null, step }))
            )
            break
          }
          case "user": {
            const step: CommunityCreationStep = "user"
            history.replace(
              ROUTE_NAMES.ONBOARDING.NEW_COMMUNITY,
              JSON.parse(JSON.stringify({ ...form.state, user: undefined, step }))
            )
            break
          }
        }
      }

      form.errors.clear() // Clear any previous errors so we can submit
      const { response, didSave: didSaveCreation } = await form.submit(
        input,
        {},
        {
          checkErrors: true,
        }
      )

      // Error, redirect to the specific step
      if ((!didSaveCreation && form?.errors) || !response?.node) {
        if (form?.errors) redirectToFormStep(form.errors[0].field)
        return false
      }

      return response.node
    },
    [form, history]
  )

  return { form, handleCreateCommunity }
}

export default useCreateCommunity
