import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoInput, DiscoText } from "@disco-ui"
import { TestIDProps } from "@utils/typeUtils"
import { observer } from "mobx-react-lite"

interface Props extends TestIDProps {
  description?: string | null
  onChange: (val: string) => void
}

function OrganizationDescriptionInputWithCounter({
  testid,
  description,
  onChange,
}: Props) {
  const classes = useStyles()
  return (
    <>
      <DiscoInput
        className={classes.descriptionInput}
        inputProps={{ "data-testid": `${testid}.description.input` }}
        value={description || ""}
        onChange={(e) => onChange(e.target.value)}
        fullWidth
        multiline
      />
      <div className={classes.characterCount}>
        <DiscoText
          variant={"body-sm"}
          color={"text.secondary"}
          testid={`${testid}.description.character-count`}
        >{`${description?.length || 0}/1000`}</DiscoText>
      </div>
    </>
  )
}

const useStyles = makeUseStyles((theme) => ({
  descriptionInput: {
    height: "200px",
    overflowY: "auto",
  },
  characterCount: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    marginTop: theme.spacing(0.5),
  },
}))

export default observer(OrganizationDescriptionInputWithCounter)
