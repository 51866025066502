import Badge from "@/admin/experiences/badges/Badge"
import AppsSidebarDragDropProvider from "@/apps/sidebar-item/AppsSidebarDragDropProvider"
import AppsSidebarList from "@/apps/sidebar-item/AppsSidebarList"
import { useLabel } from "@/core/context/LabelsContext"
import { useStreamChat } from "@/core/context/StreamChatContext"
import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import { useExperienceAdminDropdownItems } from "@/experience/admin/dropdown/ExperienceAdminDropdown"
import CommunitySidebarItem from "@/organization/common/sidebar/CommunitySidebarItem"
import generateCoursePaths from "@/organization/common/sidebar/my-experiences-list/util/generateCoursePaths"
import { MyExperiencesListItemFragment$key } from "@/organization/common/sidebar/my-experiences-list/__generated__/MyExperiencesListItemFragment.graphql"
import ProductStatusChip from "@/product/common/status-chip/ProductStatusChip"
import LeaveProductButton from "@/product/member/admin/enrollment/LeaveProductButton"
import ProductAddAppButton from "@/product/sidebar/ProductAddAppButton"
import useIsWaitingRoomEnabled from "@/product/util/hook/useIsWaitingRoomEnabled"
import { DiscoIcon, DiscoIconButton, DiscoText, DiscoTooltip } from "@disco-ui"
import DiscoDropdownItem from "@disco-ui/dropdown/DiscoDropdownItem"
import usePermissions from "@utils/hook/usePermissions"
import { observer } from "mobx-react-lite"
import { DraggableProvidedDragHandleProps } from "react-beautiful-dnd"
import { graphql, useFragment } from "react-relay"

type MyExperiencesListItemProps = {
  productKey: MyExperiencesListItemFragment$key
  testid: string
  dragHandleProps?: DraggableProvidedDragHandleProps
  isDragging: boolean
}

function MyExperiencesListItem({
  productKey,
  testid,
  dragHandleProps,
  isDragging,
}: MyExperiencesListItemProps) {
  const experienceLabel = useLabel("experience")

  const product = useFragment<MyExperiencesListItemFragment$key>(
    graphql`
      fragment MyExperiencesListItemFragment on Product {
        id
        name
        slug
        status
        badge {
          ...BadgeFragment
        }
        ...usePermissionsFragment
        ...useIsWaitingRoomEnabledActiveProductFragment
        ...ExperienceAdminDropdownFragment
        ...LeaveProductButtonFragment
        viewerMembership {
          id
        }
      }
    `,
    productKey
  )

  const permissions = usePermissions(product)
  const { viewerMembership } = product
  const COURSE_PATHS = generateCoursePaths(product.slug)
  const isViewingProduct = location.pathname.startsWith(`${COURSE_PATHS.ROOT}/`)
  const isSelected = location.pathname === COURSE_PATHS.DASHBOARD
  const classes = useStyles()
  const { manageProductItems: experienceAdminDropdownItems, manageAppsAndMembersItems } =
    useExperienceAdminDropdownItems({
      testid: "MyExperiencesListItem.more-actions",
      productKey: product,
    })
  const { isWaitingRoomEnabled } = useIsWaitingRoomEnabled({ productKey: product })

  const { productMemberChannels } = useStreamChat()
  const streamChannelIds = productMemberChannels
    .filter((c) => c.productId === product.id)
    .map((cc) => cc.externalChannelId)

  const rightContent = getRightContent()

  return (
    <CommunitySidebarItem
      testid={testid}
      name={product.name}
      isDragging={isDragging}
      dragHandleProps={dragHandleProps}
      leftIcon={
        product.badge ? (
          <Badge isSelected={isSelected} badgeKey={product.badge} size={24} />
        ) : (
          <div className={classes.emptyBadgePlaceholder} />
        )
      }
      to={COURSE_PATHS.DASHBOARD}
      selected={isSelected}
      showItemsRoute={COURSE_PATHS.ROOT}
      notificationConfig={{
        filter: {
          productId: product.id,
          kinds: [
            "assignment-submitted",
            "course-content-publish",
            "quiz-submitted",
            "survey-submitted",
          ],
        },
        streamChannelIds,
      }}
      rightContent={rightContent}
      showOnHoverRightContent={getHoverRightContent()}
      overflowItems={getOverflowItems()}
      indentNestedItems
    >
      {isViewingProduct && !isWaitingRoomEnabled && (
        <AppsSidebarDragDropProvider productId={product.id}>
          <AppsSidebarList productId={product.id} testid={testid} />
        </AppsSidebarDragDropProvider>
      )}
    </CommunitySidebarItem>
  )

  function getRightContent() {
    return (
      <>
        {product.status === "draft" && (
          <ProductStatusChip status={product.status} testid={testid} />
        )}
        {!viewerMembership && !isViewingProduct && (
          <DiscoTooltip
            interactive
            buttonProps={{ classes: { root: classes.notRegistered } }}
            content={
              <DiscoText variant={"body-xs-600"}>
                {`You're not registered for this ${experienceLabel.singular} and will not receive notifications for activity.`}
              </DiscoText>
            }
          />
        )}
      </>
    )
  }

  function getHoverRightContent() {
    // Show the tooltip icon when hovering if not a member of this product
    if (!viewerMembership && !isViewingProduct) return rightContent
    if (!permissions.has("apps.manage")) return null
    return (
      <ProductAddAppButton productId={product.id}>
        {(buttonProps) => {
          return (
            <DiscoTooltip
              content={"Add"}
              onClick={(e) => {
                // This is needed to stop us from getting pushed into the product
                e.stopPropagation()
                e.preventDefault()
                buttonProps.onClick(e)
              }}
            >
              <DiscoIconButton
                className={classes.addIconButton}
                testid={`${testid}.add-app`}
              >
                <DiscoIcon icon={"add"} />
              </DiscoIconButton>
            </DiscoTooltip>
          )
        }}
      </ProductAddAppButton>
    )
  }

  function getOverflowItems() {
    if (!viewerMembership && !isViewingProduct) return undefined
    const items = experienceAdminDropdownItems.length
      ? experienceAdminDropdownItems
      : viewerMembership
      ? [
          <LeaveProductButton
            key={`MyExperiencesListItem.more-actions.${product.slug}.leave`}
            productKey={product}
          >
            {({ onClick }) => (
              <DiscoDropdownItem
                onClick={onClick}
                testid={`MyExperiencesListItem.more-actions.${product.slug}.leave`}
                title={`Leave ${experienceLabel.singular}`}
                icon={"logout"}
              />
            )}
          </LeaveProductButton>,
        ]
      : []
    return (
      <>
        {items}
        {manageAppsAndMembersItems}
      </>
    )
  }
}

const useStyles = makeUseStyles((theme) => ({
  emptyBadgePlaceholder: {
    height: "24px",
    width: "24px",
    borderRadius: theme.measure.borderRadius.default,
    backgroundColor: theme.palette.background.paper,
  },
  addIconButton: {
    padding: theme.spacing(0.375),
    "&:hover": {
      background: "rgba(0,0,0,.1)",
    },
  },
  notRegistered: {
    // Increase specificity to override icon color when not selected
    ".DiscoSideBarItem__right-content & svg": {
      color: theme.palette.groovy.neutral[500],
    },
    opacity: 0.5,
  },
}))

export default observer(MyExperiencesListItem)
