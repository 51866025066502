/**
 * @generated SignedSource<<39db37058cb7fd07d90b9a81c9d7678d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type BadgeKind = "emoji" | "icon" | "upload" | "%future added value";
export type CommunityTemplateKind = "basic_community" | "virtual_school" | "incubator" | "training_program" | "membership" | "association" | "product_enablement" | "internal_team" | "onboarding" | "upskilling" | "compliance" | "customer_education" | "partner_education" | "accelerator" | "virtual_academy" | "professional_association" | "membership_community" | "%future added value";
export type CompleteCommunityCreationInput = {
  organization?: CreateCommunityOrganizationInput | null;
  organizationDescription?: CreateCommunityOrganizationDescriptionInput | null;
  user?: UpdateUserProfileStepInput | null;
  attributes?: ReadonlyArray<CommunityAttributeInput> | null;
  template?: CommunityTemplateKind | null;
  templateVersion?: number | null;
};
export type CreateCommunityOrganizationInput = {
  name: string;
  slug: string;
  logo?: string | null;
  timezone?: string | null;
  coverUrl?: string | null;
  description?: string | null;
  visibility?: string | null;
  template?: CommunityTemplateKind | null;
  badge?: BadgeInput | null;
};
export type BadgeInput = {
  icon?: string | null;
  color?: string | null;
  emoji?: string | null;
  mediaUrl?: string | null;
  assetId?: string | null;
  kind: BadgeKind;
};
export type CreateCommunityOrganizationDescriptionInput = {
  description?: string | null;
};
export type UpdateUserProfileStepInput = {
  firstName: string;
  lastName: string;
  bio?: string | null;
  avatar?: string | null;
  cover?: string | null;
};
export type CommunityAttributeInput = {
  name: string;
  value: string;
};
export type useCreateCommunityMutation$variables = {
  input: CompleteCommunityCreationInput;
};
export type useCreateCommunityMutation$data = {
  readonly response: {
    readonly node: {
      readonly slug: string;
      readonly id: string;
      readonly primaryDomain: string;
      readonly products: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly id: string;
            readonly slug: string;
            readonly name: string;
          };
        }>;
      };
      readonly badge: {
        readonly id: string;
        readonly color: string | null;
      } | null;
    } | null;
    readonly errors: ReadonlyArray<{
      readonly field: string;
      readonly message: string;
    }> | null;
  };
};
export type useCreateCommunityMutation = {
  variables: useCreateCommunityMutation$variables;
  response: useCreateCommunityMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = [
  {
    "alias": "response",
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CompleteCommunityCreationResponse",
    "kind": "LinkedField",
    "name": "completeCommunityCreation",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "primaryDomain",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ProductNodeConnection",
            "kind": "LinkedField",
            "name": "products",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ProductNodeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Product",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v1/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Badge",
            "kind": "LinkedField",
            "name": "badge",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "color",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ValidationError",
        "kind": "LinkedField",
        "name": "errors",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "field",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "message",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useCreateCommunityMutation",
    "selections": (v3/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useCreateCommunityMutation",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "9d227822a94d32ab6ecaf2da30ab9e6d",
    "id": null,
    "metadata": {},
    "name": "useCreateCommunityMutation",
    "operationKind": "mutation",
    "text": "mutation useCreateCommunityMutation(\n  $input: CompleteCommunityCreationInput!\n) {\n  response: completeCommunityCreation(input: $input) {\n    node {\n      slug\n      id\n      primaryDomain\n      products {\n        edges {\n          node {\n            id\n            slug\n            name\n          }\n        }\n      }\n      badge {\n        id\n        color\n      }\n    }\n    errors {\n      field\n      message\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "58574e3f557c944a3153c4d18f3a8288";

export default node;
