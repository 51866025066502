import CreateBadgeModalButton from "@/admin/experiences/badges/CreateBadgeModalButton"
import { CommunityCreationFormState } from "@/authentication/create-community/CreateCommunityPage"
import { CreateCommunityPageMutation } from "@/authentication/create-community/__generated__/CreateCommunityPageMutation.graphql"
import FormStore from "@/core/form/store/FormStore"
import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import FileDropzone, { IMAGE_FILE_TYPES } from "@components/dropzone/FileDropzone"
import { DiscoFormControl, DiscoInput, DiscoText, DiscoTextButton } from "@disco-ui"
import DiscoImage from "@disco-ui/image/DiscoImage"
import { ASPECT_RATIOS } from "@utils/image/imageConstants"
import { observer } from "mobx-react-lite"

interface Props {
  validationForm: FormStore<CommunityCreationFormState, CreateCommunityPageMutation>
}

function CreateCommunityForm({ validationForm }: Props) {
  const classes = useStyles()

  return (
    <div data-testid={"CreateCommunityForm.wrapper"}>
      <DiscoText variant={"heading-lg"} style={{ alignSelf: "flex-start" }}>
        {"Create your community"}
      </DiscoText>
      <DiscoText
        variant={"body-md"}
        marginBottom={3}
        marginTop={1}
        align={"left"}
        setColor={"#3F3F46"}
      >
        {
          "Review, edit and continue to set up your new beautiful home for your community to learn together!"
        }
      </DiscoText>

      <div className={classes.dropzoneContainer}>
        <div>
          <DiscoFormControl label={"Icon"}>
            <CreateBadgeModalButton
              variant={"onboarding"}
              badge={validationForm.state.organization!.badge!}
            />
          </DiscoFormControl>
        </div>
        <div>
          <DiscoFormControl label={"Logo"} optional>
            {validationForm.state.organization?.logo ? (
              <div className={classes.logoContainer}>
                <div className={classes.imageContainer}>
                  <DiscoImage
                    src={validationForm.state.organization.logo}
                    alt={"Community Logo"}
                    style={{
                      maxHeight: "58px",
                      width: "auto",
                      maxWidth: "100%",
                    }}
                  />
                </div>
                <DiscoTextButton
                  onClick={() => {
                    validationForm.state.organization!.logo = null
                  }}
                >
                  {"Remove"}
                </DiscoTextButton>
              </div>
            ) : (
              <FileDropzone
                variant={"upload-icon"}
                className={classes.dropzone}
                showSupportedFiles={false}
                dropzoneOptions={{ accept: IMAGE_FILE_TYPES }}
                onUpload={(result) =>
                  (validationForm.state.organization!.logo = result.url)
                }
                // Hide the upload icon because it doesn't fit in the short container
                messageIcon={<></>}
                message={
                  <DiscoText className={classes.dropzoneText}>
                    <u style={{ fontSize: "inherit", lineHeight: "inherit" }}>
                      {"Browse"}
                    </u>
                    {" or drop your cover here"}
                  </DiscoText>
                }
                suggestedDimensions={{ width: 450, height: 150 }}
                cropperProps={{
                  stencilProps: { aspectRatio: ASPECT_RATIOS.ORGANIZATION_LOGO },
                }}
              />
            )}
          </DiscoFormControl>
        </div>
      </div>
      <div className={classes.nameDomainContainer}>
        <DiscoFormControl
          label={"Community Name"}
          className={classes.inputForm}
          errorMessages={validationForm.errorsByField.name}
          error={Boolean(validationForm.errorsByField.name)}
          testid={"CreateOrganizationForm.form-control.community-name"}
        >
          <DiscoInput
            value={validationForm.state.organization?.name}
            name={"organizationName"}
            placeholder={"Strategy School"}
            className={classes.input}
            onChange={handleNameChange}
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus
          />
        </DiscoFormControl>
        <DiscoFormControl
          label={"Domain"}
          errorMessages={validationForm.errorsByField.slug}
          error={Boolean(validationForm.errorsByField.slug)}
          testid={"CreateOrganizationForm.form-control.community-slug"}
        >
          <div className={classes.domainContainer}>
            <DiscoInput
              value={validationForm.state.organization!.slug}
              name={"organizationSlug"}
              placeholder={"strategy-school"}
              className={classes.domainInput}
              fullWidth
              onChange={(e) =>
                (validationForm.state.organization!.slug = e.target.value.toLowerCase())
              }
            />
            <div className={classes.slugContainer}>
              <DiscoText
                className={classes.slugSuffix}
                variant={"body-sm"}
                color={"groovy.neutral.400"}
              >
                {".disco.co"}
              </DiscoText>
            </div>
          </div>
        </DiscoFormControl>
      </div>
    </div>
  )

  function handleNameChange(event: React.ChangeEvent<HTMLInputElement>) {
    validationForm.state.organization!.name = event.target.value
    validationForm.state.organization!.slug = event.target.value
      .trim()
      .toLowerCase()
      // replaces all special characters except for spaces
      .replace(/[^A-Z0-9 -]/gi, "")
      .replaceAll(" ", "-")
  }
}

const useStyles = makeUseStyles((theme) => ({
  slugSuffix: {
    marginRight: theme.spacing(1.5),
  },
  slugContainer: {
    display: "flex",
    borderLeft: theme.palette.constants.borderSmall,
    height: "40px",
    paddingLeft: theme.spacing(1),
    alignItems: "center",
  },
  domainInput: {
    width: "100%",
    borderTopLeftRadius: theme.measure.borderRadius.medium,
    borderBottomLeftRadius: theme.measure.borderRadius.medium,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },
  input: {
    borderRadius: theme.measure.borderRadius.big,
  },
  inputForm: {
    [theme.breakpoints.down("md")]: {
      marginBottom: 0,
    },
  },
  dropzoneContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    gap: theme.spacing(2.5),
    width: "100%",
    "& > :last-child": {
      flexGrow: 1,
    },
  },
  dropzone: {
    width: "max-content",
    minWidth: "50%",
    height: "72px",
  },
  dropzoneText: {
    fontSize: "14px",
    lineHeight: "22px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
      lineHeight: "13px",
    },
  },
  domainContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    height: "40px",
    borderRadius: theme.measure.borderRadius.medium,
    backgroundColor: theme.palette.groovy.neutral[100],
  },
  imageContainer: {
    display: "flex",
    justifyContent: "center",
    width: "fit-content",
    backgroundColor: theme.palette.common.white,
    border: "1px solid #D5D9DE",
    borderRadius: theme.measure.borderRadius.big,
    padding: theme.spacing(1, 3),
    maxHeight: "72px",
  },
  logoContainer: {
    display: "flex",
    width: "fit-content",
    flexDirection: "column",
    justifyContent: "center",
  },
  nameDomainContainer: {
    display: "flex",
    gap: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      gap: theme.spacing(0.5),
    },
  },
}))

export default observer(CreateCommunityForm)
