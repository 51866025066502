import {
  CommunityCreationFormState,
  OrganizationAttribute,
} from "@/authentication/create-community/CreateCommunityPage"
import { CreateCommunityPageMutation } from "@/authentication/create-community/__generated__/CreateCommunityPageMutation.graphql"
import FormStore from "@/core/form/store/FormStore"
import CommunityMultipleChoiceQuestion from "@/organization/create/form/CommunityMultipleChoiceQuestion"
import { observer } from "mobx-react-lite"

type Props = {
  validationForm: FormStore<CommunityCreationFormState, CreateCommunityPageMutation>
}

const ATTR_NAME: OrganizationAttribute = "new_or_existing"

function NewOrExistingCommunityQuestion({ validationForm }: Props) {
  const attr = validationForm.state.attributes.find((a) => a.name === ATTR_NAME)

  return (
    <CommunityMultipleChoiceQuestion
      testid={"NewOrExistingCommunityQuestion"}
      title={"Let's get started. Where are you at in your journey?"}
      subtitle={
        "We'll use this to help you get setup based on your business needs right now."
      }
      options={[
        {
          subtitle: "I have an existing community or learning business",
          value: "existing",
        },
        { subtitle: "I'm just getting started", value: "new" },
      ]}
      value={attr?.value}
      onChange={(value) => {
        if (attr) {
          attr.value = value
          return
        }
        validationForm.state.attributes.push({ name: ATTR_NAME, value })
      }}
    />
  )
}

export default observer(NewOrExistingCommunityQuestion)
