import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { useFormStore } from "@/core/form/store/FormStore"
import { DeleteOccurrenceButtonPreloadedQuery } from "@/occurrence/button/delete-button/DeleteOccurrenceButton"
import { DeleteOccurrenceButtonModalPreloadedQuery } from "@/occurrence/button/delete-button/__generated__/DeleteOccurrenceButtonModalPreloadedQuery.graphql"
import {
  DeleteEventInput,
  DeleteOccurrenceModalMutation,
} from "@/occurrence/button/delete-button/__generated__/DeleteOccurrenceModalMutation.graphql"
import RelayEnvironment from "@/relay/RelayEnvironment"
import Relay from "@/relay/relayUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { displaySuccessToast } from "@components/toast/ToastProvider"
import { DiscoRadio } from "@disco-ui"
import DiscoWarningModal from "@disco-ui/modal/DiscoWarningModal"
import { RadioGroup } from "@material-ui/core"
import { TestIDProps } from "@utils/typeUtils"
import { observer } from "mobx-react-lite"
import { PreloadedQuery, usePreloadedQuery } from "react-relay"
import ConnectionHandler from "relay-connection-handler-plus"
import { commitLocalUpdate, graphql } from "relay-runtime"

type SingleOrAll = "single" | "all"

interface Props extends TestIDProps {
  isOpen: boolean
  onClose: () => void
  onDelete?: () => void
  occurrenceQueryRef: PreloadedQuery<DeleteOccurrenceButtonModalPreloadedQuery>
}

function DeleteOccurrenceModal(props: Props) {
  const {
    isOpen,
    onClose,
    occurrenceQueryRef,
    onDelete,
    testid = "DeleteOccurrenceModal",
  } = props
  const activeOrganization = useActiveOrganization()!
  const classes = useStyles()

  const { node } = usePreloadedQuery<DeleteOccurrenceButtonModalPreloadedQuery>(
    DeleteOccurrenceButtonPreloadedQuery,
    occurrenceQueryRef
  )

  const form = useFormStore<
    DeleteOccurrenceModalMutation,
    DeleteEventInput & { singleOrAll: SingleOrAll }
  >(
    graphql`
      mutation DeleteOccurrenceModalMutation($input: DeleteEventInput!) {
        response: deleteEvent(input: $input) {
          deletedOccurrences @deleteRecord
          deletedEventId @deleteRecord
          errors {
            field
            message
          }
        }
      }
    `,
    {
      singleOrAll: "single",
    }
  )

  const occurrence = Relay.narrowNodeType(node, "Occurrence")
  if (!occurrence) return null

  const { isRecurring } = occurrence.event

  return (
    <>
      {isRecurring ? (
        <DiscoWarningModal
          testid={`${testid}.delete-warning-modal`}
          isOpen={isOpen}
          onClose={onClose}
          title={"Permanently delete this event?"}
          description={
            "This event is part of a recurring event. Are you sure you want to permanently delete it? Select if you want to delete:"
          }
          body={
            <RadioGroup
              value={form.state.singleOrAll}
              onChange={(e) => (form.state.singleOrAll = e.target.value as SingleOrAll)}
            >
              <DiscoRadio
                testid={`${testid}.radio.single`}
                label={"Only this single event."}
                value={"single"}
                checked={form.state.singleOrAll === "single"}
                variant={"simple"}
                controlClasses={{ label: classes.radioLabel }}
              />
              <DiscoRadio
                testid={`${testid}.radio.all`}
                label={`All ${occurrence.event.occurrences.totalCount} events in this series (past and upcoming).`}
                value={"all"}
                checked={form.state.singleOrAll === "all"}
                variant={"simple"}
                controlClasses={{ label: classes.radioLabel }}
              />
            </RadioGroup>
          }
          confirmationButtonProps={{
            onClick: deleteOccurrence,
            shouldDisplaySpinner: form.isSubmitting,
            children:
              form.state.singleOrAll === "single" ? "Yes, delete it" : "Yes, delete them",
          }}
          modalContentLabel={"Delete Occurrence Dialog"}
        />
      ) : (
        <DiscoWarningModal
          testid={"DeleteOccurrenceModal.delete-warning-modal"}
          isOpen={isOpen}
          onClose={onClose}
          title={"Are you sure you want to delete this event?"}
          description={
            "The event will be deleted permanently. This may affect your members."
          }
          confirmationButtonProps={{
            onClick: deleteOccurrence,
            shouldDisplaySpinner: form.isSubmitting,
            children: "Yes, delete it",
          }}
          modalContentLabel={"Delete Occurrence Dialog"}
        />
      )}
    </>
  )

  async function deleteOccurrence() {
    const { didSave } = await form.submit(
      form.state.singleOrAll === "single"
        ? { occurrenceId: occurrence!.id }
        : { eventId: occurrence!.event.id }
    )
    if (!didSave) return

    if (onDelete) {
      onDelete()
    }

    commitLocalUpdate(RelayEnvironment, (store) => {
      const org = store.get(activeOrganization.id)

      if (!org) return

      ConnectionHandler.getConnections(
        org,
        "RSVPToAllOrganizationEventsButton_occurrences"
      ).forEach((connection) => connection.invalidateRecord())

      ConnectionHandler.getConnections(
        org,
        "EventsJumpToDateCalendar__occurrences"
      ).forEach((connection) => connection.invalidateRecord())
    })

    onClose()

    displaySuccessToast({
      message: "Event has been removed.",
      testid: "DeleteOccurrenceModal.confetti-emoji",
    })
  }
}

export default observer(DeleteOccurrenceModal)

const useStyles = makeUseStyles({
  radioLabel: {
    width: "unset",
  },
})

// eslint-disable-next-line no-unused-expressions
graphql`
  fragment DeleteOccurrenceModalFragment on Occurrence {
    id
    event {
      id
      isRecurring
      productId
      occurrences {
        totalCount
      }
    }
  }
`
